import algoliasearch from 'algoliasearch/lite'
import { debounce } from '#js/components/utils'
import { getAlgoliaResults } from '@algolia/autocomplete-js'

const debounced = debounce(async (items) => await items)

export function onSelect ({ item }) {
  location.href = `/search?query=${item.query}`
}

export function getItems ({ query }) {
  const searchClient = algoliasearch(globalThis.algoliaAppId, globalThis.algoliaAPIKey)

  return getAlgoliaResults({
    searchClient,
    queries: [
      {
        indexName: 'event_query_suggestions',
        query,
        params: {
          hitsPerPage: 5
        }
      }
    ]
  })
}

export function item ({ item, html, components }) {
  return html`
    <div class="aa-ItemContent" style="display: flex">
      <div class="aa-ItemIcon aa-ItemIcon--noBorder">
        <svg viewBox="0 0 24 24">
          <use href="${globalThis.svgSprite}#search"></use>
        </svg>
      </div>
      <p>
        ${components.Highlight({ hit: item, attribute: 'query' })}
      </p>
    </div>
  `
}

const suggestionsPlugin = {
  getSources () {
    return debounced([
      {
        sourceId: 'suggestionsSource',
        onSelect,
        getItems,
        templates: {
          item
        }
      }
    ])
  }
}

export default suggestionsPlugin
